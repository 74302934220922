import * as React from 'react'
import loadable from '@loadable/component'

const FormPage = loadable(() => import('/src/templates/FormPage'))

const Page = ({ postInfo }) => {
    const post = {
        canonical: '/',
        title: 'Compare <span>Life Insurance</span> Quotes - from £5/Month*'
    }

    return (
        <FormPage
            postInfo={postInfo}
            post={post}
            leadSource={'SelfGen.CTQ.Affiliate'}
        />
    )
}

export default Page
